import React, { useMemo } from "react"
import Helmet from "react-helmet"
import { BreadCrumb } from "../shared/PageHeader/PageHeader"
import slugify from "slugify"

interface SeoProps {
    location: any
    title: string
    description: string
    ogImage?: string
    breadCrumbs?: Array<BreadCrumb>
    article?: {
        title: string
        description: string
        datePublished: string
        dateEdited: string
        imageUrl: string
        slug: string
        subBlog: string
        author: {
            "@type": "Person"
            name: string
        }
    }
}

export const SEO = ({
    location,
    title,
    description,
    ogImage,
    breadCrumbs,
    article,
}: SeoProps) => {
    const structuredData = useMemo(() => {
        const data: any = [
            {
                "@context": "http://schema.org",
                "@type": "WebSite",
                url: `https://23shout.com${location?.pathname ?? ""}`,
                name: article ? article.title : title,
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                url: "https://23shout.com",
                logo: "https://23shout.com/global/logo.png",
            },
        ]

        if (breadCrumbs || article) {
            // if (breadCrumbs) {
            //     data.push({
            //         "@context": "http://schema.org",
            //         "@type": "BreadcrumbList",
            //         itemListElement: [
            //             {
            //                 "@type": "ListItem",
            //                 position: 1,
            //                 name: "Blog",
            //                 item: "https://madelinemiller.dev/blog/",
            //             },
            //             {
            //                 "@type": "ListItem",
            //                 position: 2,
            //                 name: "General",
            //                 item: "https://madelinemiller.dev/blog/category/general/",
            //             },
            //             {
            //                 "@type": "ListItem",
            //                 position: 3,
            //                 name: "Flexible working location for ALL employees is essential for accessibility",
            //                 item: "https://madelinemiller.dev/blog/flexible-working-location-everyone/",
            //             },
            //         ],
            //     })
            // } else
            if (article) {
                data.push({
                    "@context": "http://schema.org",
                    "@type": "BreadcrumbList",
                    itemListElement: [
                        {
                            "@type": "ListItem",
                            position: 1,
                            name: "Blog",
                            item: "https://23shout.com/blog",
                        },
                        {
                            "@type": "ListItem",
                            position: 2,
                            name: article.subBlog,
                            item: `https://23shout.com/blog/${slugify(
                                article.subBlog,
                                { lower: true }
                            )}`,
                        },
                        {
                            "@type": "ListItem",
                            position: 3,
                            name: article.title,
                            item: `https://23shout.com/${article.slug}`,
                        },
                    ],
                })
            }
        }

        if (article) {
            data.push({
                "@context": "http://schema.org",
                "@type": "BlogPosting",
                url: `https://23shout.com/${article.slug}`,
                name: `${article.title}`,
                headline: `${article.title}`,
                description: `${article.description}`,
                image: [`https://23shout.com${article.imageUrl}`],
                author: {
                    "@type": "Person",
                    name: `${article.author.name}`,
                },
                publisher: {
                    "@type": "Organization",
                    name: "23shout",
                    url: "https://23shout.com/",
                    logo: {
                        "@type": "ImageObject",
                        url: "/image/global/logo.png",
                    },
                },
                datePublished: article.datePublished,
                dateModified: article.dateEdited,
                mainEntityOfPage: {
                    "@type": "WebPage",
                    "@id": `https://23shout.com/${article.slug}`,
                },
            })
        }

        return data
    }, [breadCrumbs, article])

    return (
        <Helmet>
            <title>{title}</title>

            <meta name="title" content={`${title}`} />
            <meta name="description" content={`${description}`} />

            <meta property="og:type" content="website" />
            <meta property="og:title" content={`${title}`} />
            <meta property="og:description" content={`${description}`} />

            {!!ogImage && <meta property="og:image" content={`${ogImage}`} />}
            {!!ogImage && (
                <meta property="twitter:image" content={`${ogImage}`} />
            )}

            <meta property="twitter:card" content="summary_large_image" />

            <meta property="twitter:title" content={`${title}`} />
            <meta property="twitter:description" content={`${description}`} />

            <script type={"application/ld+json"}>
                {JSON.stringify(structuredData)}
            </script>
        </Helmet>
    )
}
