import React from "react"
import styled from "styled-components"
import { Page } from "../../shared/Page/Page"
import { SEO } from "../../utils/SEO"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { H1, H2 } from "../../shared/Ui/Typography/Typography"
import { blue, blueGrey } from "../../utils/colors"

const Container = styled.div`
    max-width: 800rem;
    margin: 50rem auto 0;

    h1 {
        font-size: 40rem;
        margin-top: 5rem;
    }

    h2 {
        font-size: 28rem;
        line-height: 30rem;
        margin-top: 60rem;
        color: ${({ theme }) => theme.text.normal};
    }

    h3 {
        font-size: 20rem;
        margin-top: 60rem;
        margin-bottom: 0;
        color: ${({ theme }) => theme.text.normal};
        font-family: "Gilroy-Bold", sans-serif;
    }

    h4 {
        font-size: 20rem;
        margin-top: 30rem;
        margin-bottom: 10rem;
        font-family: "Gilroy-SemiBold", sans-serif;
        color: ${({ theme }) => theme.text.normal};
    }

    h1,
    h2,
    h3,
    h4 {
        &:hover {
            .anchor.before svg {
                visibility: visible;
            }
        }

        .anchor.before {
            position: absolute;
            left: -25rem;
            padding-right: 10rem;

            svg {
                visibility: hidden;

                &:hover {
                    fill: ${({ theme }) => theme.primary};
                }
            }
        }
    }

    p,
    li {
        color: ${({ theme }) => theme.text.normal};
        line-height: 27rem;
    }

    p {
        line-height: 27rem;
    }

    li {
        //margin: 0 0 5rem;
    }

    table {
        border-collapse: collapse;
        margin: 25px 0;
        font-size: 0.9em;
        font-family: sans-serif;
        min-width: 400px;
        border-radius: 8rem;
        overflow: hidden;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
            rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

        thead tr {
            background-color: ${blue[500]};
            text-align: left;

            th {
                color: ${blue[50]}!important;
            }
        }

        th,
        td {
            padding: 12px 15px;
            color: ${blueGrey[500]};
        }

        tbody tr {
            border-bottom: 1px solid ${blueGrey[200]};
        }

        tbody tr:nth-of-type(even) {
            background-color: ${blueGrey[50]};
        }

        tbody tr:last-of-type {
            border-bottom: 2px solid ${blue[500]};
        }
    }
`

const PageSimple = ({ location, ...props }) => {
    const context = props.pageContext

    return (
        <Page constrained location={location} disableCta>
            <SEO
                location={location}
                title={`${context.frontmatter.title} - 23shout`}
                description={context.frontmatter.description}
            />
            <Container>
                <MDXRenderer children={context.body} />
            </Container>
        </Page>
    )
}

export default PageSimple
